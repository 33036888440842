<template>
    <el-dialog title="查看档案信息" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible"
               class="collectForm">
        <div v-loading="loading" class="formBox">
            <el-form size="small" :inline="true" :model="inputForm" ref="inputForm"
                     label-width="180px" disabled class="recordCollect">
                <el-descriptions title="档案分类"></el-descriptions>
                <el-form-item label="档案分类：">
                    <el-input v-model="archivesBasicName"></el-input>
                </el-form-item>
                <el-descriptions title="档案信息"></el-descriptions>
                <el-form-item class="rowItem" label="题名：" prop="archivesDataName">
                    <el-input
                            type="textarea"
                            :rows="1"
                            placeholder="请输入题名"
                            maxlength="100"
                            v-model.trim="inputForm.archivesDataName">
                    </el-input>
                </el-form-item>
                <el-form-item label="状态：">
                    <el-select class="w100i" v-model="warehousingState">
                        <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in $dictUtils.getDictList('warehousing_state')">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="全宗号：" prop="quanzongNumber">
                    <el-input placeholder="请输入全宗号（限50字）"
                              maxlength="50"
                              v-model.trim="inputForm.quanzongNumber">
                    </el-input>
                </el-form-item>
                <el-form-item label="全宗名称：" prop="quanzongName">
                    <el-input placeholder="请输入全宗名称（限50字）"
                              maxlength="50"
                              v-model.trim="inputForm.quanzongName">
                    </el-input>
                </el-form-item>
                <el-form-item label="档号：" prop="reference">
                    <el-input placeholder="请输入档号（限50字）"
                              maxlength="50"
                              v-model.trim="inputForm.reference">
                    </el-input>
                </el-form-item>
                <el-form-item :label="item.forName ? item.forName : item.basicName + '：'" :prop="item.code"
                              v-for="(item, index) in config"
                              :key="index">
                    <!--字符-->
                    <el-input :type="item.maxLength && item.maxLength > 100 ? 'textarea': ''"
                              v-model.trim="inputForm[item.code]"
                              v-if="item.archivesBasicTypeCode == 'text' && !item.archivesBasicMultiselectList.length"></el-input>
                    <!--数值、浮点数-->
                    <el-input v-model.trim="inputForm[item.code]"
                              v-if="item.archivesBasicTypeCode == 'integer' || item.archivesBasicTypeCode == 'double'"></el-input>
                    <!--下拉选择-->
                    <el-select class="w100i" v-model="inputForm[item.code]"
                               :multiple="item.archivesBasicMultiselectList[0].selectBy == 1"
                               v-if="item.archivesBasicMultiselectList.length">
                        <el-option v-for="(v,i) in item.archivesBasicMultiselectList" :key="i"
                                   :label="v.archivesBasicValue"
                                   :value="v.archivesBasicValue"></el-option>
                    </el-select>
                    <!--时间-->
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    type="datetime" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd HH:mm:ss' || item.archivesBasicTypeCode == 'date' && !item.dataFormat">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyy-MM-dd'">
                    </el-date-picker>
                    <el-date-picker class="w100i"
                                    v-model="inputForm[item.code]" value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyyMMdd"
                                    type="date" clearable placeholder="请选择"
                                    v-if="item.archivesBasicTypeCode == 'date' && item.dataFormat == 'yyyyMMdd'">
                    </el-date-picker>
                </el-form-item>
                <el-descriptions :title="utilizeName + '信息'"></el-descriptions>
                <el-form-item :label="utilizeName + '：'">
                    <el-input v-model="row.createByName"></el-input>
                </el-form-item>
                <el-form-item :label="utilizeName + '时间：'">
                    <el-input v-model="row.createDate"></el-input>
                </el-form-item>
                <el-form-item :label="utilizeName + '档案：'">
                    <el-input v-model="row.fileName"></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="text_center p_t2">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>

    export default {
        data() {
            return {
                id: '',
                archivesBasicDataId: '',
                archivesBasicName: '',
                visible: false,
                method: '',
                loading: false,
                config: [],
                inputForm: {},
                warehousingState: '', // 档案状态
                row: {},
                utilizeName: '',
            }
        },
        methods: {
            init(row, method, recordTypeId, idType) {
                this.id = ''
                this.archivesBasicDataId = recordTypeId
                this.loading = true
                this.method = method
                if (row) {
                    this.row = row
                    this.id = row.archivesDataId
                    if (row.utilizeType == 0) {
                        this.utilizeName = '查看'
                    } else if (row.utilizeType == 1) {
                        this.utilizeName = '下载'
                    } else if (row.utilizeType == 2) {
                        this.utilizeName = '打印'
                    }
                }
                this.getRecord(recordTypeId)
                this.visible = true
            },
            // 获取数据类型
            getRecord(id) {
                this.$axios(this.api.record.basicdataGetById + '/' + id).then(data => {
                    if (data.status) {
                        this.config = data.data.archivesBasicDataGroup
                        this.archivesBasicName = data.data.archivesBasicName
                        this.getInfo()
                    }
                })
            },
            // 查询详情数据
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.getById, {
                    archivesBasicDataId: this.archivesBasicDataId,
                    id: this.id
                }, 'get').then((res) => {
                    if (res.status) {
                        this.loading = false
                        this.inputForm = res.data.archivesData
                        this.warehousingState = res.data.warehousingState
                        this.inputForm.archivesDataName = res.data.archivesDataName
                        this.inputForm.quanzongNumber = res.data.quanzongNumber
                        this.inputForm.quanzongName = res.data.quanzongName
                        this.inputForm.reference = res.data.reference
                        for (let key in this.inputForm) {
                            this.config.map(item => {
                                if (item.code == key && item.archivesBasicMultiselectList.length && item.archivesBasicMultiselectList[0].selectBy == 1) {
                                    if (this.inputForm[key]) {
                                        this.inputForm[key] = this.inputForm[key].split(',')
                                    } else {
                                        this.inputForm[key] = []
                                    }
                                }
                            })
                        }
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .rowItem {
        width: 97% !important;
    }
</style>
